/* Component Dependencies */
var hotelHeaderTemplate = require('templates/hotelHeader.hbs');
var hotelHeaderReservationTemplate = require('templates/hotelHeader-reservation.hbs');
var hotelHeaderErsTemplate = require('templates/hotelHeader-ers.hbs');
var hotelHeaderContactInfoTemplate = require('templates/hotelHeader-contactInfo.hbs');
var hotelHeaderHSIATemplate = require('templates/hotelHeader-HSIA.hbs');
var hotelHeaderCheckInConfirmationTemplate = require('templates/hotelHeader-checkInConfirmation.hbs');
var AriesComponent = require('libs/aries-component');
var _ = require('lodash');
var $ = require('jquery');
var Popup = require('libs/popup');

AriesComponent.create({
  type: 'hotelHeader',
  template: {
    'hotelHeader': hotelHeaderTemplate,
    'hotelHeader-reservation': hotelHeaderReservationTemplate,
    'hotelHeader-ers': hotelHeaderErsTemplate,
    'hotelHeader-contactInfo': hotelHeaderContactInfoTemplate,
    'hotelHeader-HSIA': hotelHeaderHSIATemplate,
    'hotelHeader-checkInConfirmation': hotelHeaderCheckInConfirmationTemplate
  },
  events: {
    'click .js-contact-details-container': 'togglePhoneContainerDisplay',
    'click .details-btn-wrapper': 'openDetailedView',
    'click .close-btn-wrapper': 'hideDetailedView',
    'click .phone-numbers, .l-phone-number-dropdown': 'handlePhoneNumber',
    'click .js-phone-number': 'togglePhoneContainerDisplay',
    'click #js-collapse-brand-bar': 'toggleSubMenu',
    'click .js-phone-number, .icon-phone': 'toggleContactDetails',
    'click #email-container': 'toggleEmailContainerDisplay',
    'click .email-link, .icon-email': 'toggleEmailDetails',
    'click .email-list': 'handleEmail',
    'click .js-content-wrapper .address-wrapper a': 'scrollToMap'
  },
  unBindEvents: function($) {
    $('*[data-component-id="' + this.$el.data('component-id') + '"]').off();
    $('*[data-component-id="' + this.$el.data('component-id') + '"] *').off();
  },
  /**
  * This function is used to toggle the dropdown on clicking the phone icon 
  * @param {Object} event Mouse event triggered on phone number dropdown click. 
  */
  togglePhoneContainerDisplay: function (event) {
    var docWidth = window.innerWidth,
      phoneContainerOffset,
      phoneContainerWidth,
      spaceForFlyout;
    event.preventDefault();
    event.stopPropagation();
    this.phoneNumberContainer = this.$el.find('.l-phone-number-dropdown');
    phoneContainerOffset = this.$el.find('.js-contact-details-container').offset();
    phoneContainerWidth = this.phoneNumberContainer.innerWidth();
    spaceForFlyout = docWidth - phoneContainerOffset.left;
    if(this.phoneNumberContainer) {
      if(spaceForFlyout < phoneContainerWidth){
        this.phoneNumberContainer.css('left', spaceForFlyout-phoneContainerWidth-20);
        this.phoneNumberContainer.addClass('js-flyout-right');
      }
      if(this.isPhoneListOpen) {
        this.phoneNumberContainer.slideUp('fast');
      } else {
        this.phoneNumberContainer.slideDown('fast');
      }
      this.isPhoneListOpen = !this.isPhoneListOpen;
    }
  },
  /**
  * This function is used to toggle the dropdown on clicking the email icon 
  * @param {Object} event Mouse event triggered on phone number dropdown click. 
  */
  toggleEmailContainerDisplay: function (event) {
    event.stopPropagation();
    this.emailNumberContainer = this.$el.find('.email-list');
    if(this.emailNumberContainer.length > 0) {
      if(this.isEmailListOpen) {
        this.emailNumberContainer.slideUp();
      } else {
        this.emailNumberContainer.slideDown();
      }
      this.isEmailListOpen = !this.isEmailListOpen;
    }
  },
  bindEvents: function () {
    $(document).on('click', _.bind(this.onDocumentClick, this));
    this.showPopup();
    if(this.$el.hasClass('tile-hotel-header')) {
      this.handleKeyboardControls();

      if($('.tile-hotel-header .mi-popover').length) {
      var headerComponentsPopup = this.registerPopup({
        selector: '.tile-hotel-header .mi-popover',
        sourceBlock: '.modal-content',
        alternateCloseMarkup: true
      });
      headerComponentsPopup.register();
      }
      if (this.$el.find('.currency-calculator-link').length) {
        var currencyCalculatorERSPopup = new Popup({
            selector: '.currency-calculator-link>a',
            sourceBlock: '.modal-content',
            alternateCloseMarkup: true
        });
        currencyCalculatorERSPopup.register();
      }
      if (this.$el.find('.js-toll-free-reservation-url').length) {
        var tollFreeReservationUrlPopUp = new Popup({
            selector: '.js-toll-free-reservation-url',
            sourceBlock: '.modal-content',
            alternateCloseMarkup: true
        });
        tollFreeReservationUrlPopUp.register();
      }
      if (this.$el.find('.js-rewards-category-link-popup, .tier-link').length) {
        var rewardsERSPopUp = new Popup({
            selector: '.js-rewards-category-link-popup>a, .tier-link>a',
            sourceBlock: '.modal-content'
        });
        rewardsERSPopUp.register();
      }

      if( this.$el.find(".js-reserve-now-link") ){
        var reserveNowPopup = new Popup({
            selector: '.tile-hotel-header .js-reserve-now-link',
            sourceBlock: '.modal-content',
            focus: '.mfp-close',
            alternateCloseMarkup: true,
            afterClose: function() {
              $(document.body).removeAttr('style')
            }
        });
        reserveNowPopup.register();
      }
    }
    
    if(this.$el.hasClass('tile-brand-bar')) {
      var brandBarPhotoGallery, rewardsPopUp, currencyCalculatorPopup;

      brandBarPhotoGallery = this.registerPopup({
        selector: '[data-component-id="' + this.$el.data('component-id') + '"] .mi-popover',
        url: this.$el.find('.hotel-photo-detail').attr('rel'),
        mainClass: 'm-photo-gallery-container',
        reverseCloseIcon: true
      });
      brandBarPhotoGallery.register();

      rewardsPopUp = this.registerPopup({
        selector: '.tile-brand-bar .js-category',
        reverseCloseIcon: true
      });
      rewardsPopUp.register();

      if(this.$el.find('.js-currency-calculator').length) {
        currencyCalculatorPopup = this.registerPopup({
          selector: '.tile-brand-bar .js-currency-calculator'
        });
        currencyCalculatorPopup.register();
      }
    }
    if(this.$el.hasClass('tile-hotel-header-HSIA')) {
      $('.m-header-container').addClass('hws-header');
    }
    this.$el.on('click', '.book-next-stay', this.openOverlay);
    this.registerReviewPopup();
    /** 
    * This function is used for removing white space in uri
    */
    if(this.$el.hasClass('tile-hotel-header-checkin-confirmation')) {
      this.removeSpace();
    }
  },
  /**
  * This function registers Popup for the "see details" anchor
  */
  registerReviewPopup : function() {
    var detailsPopup = new Popup({
        selector: '.show-review .mi-popover',
        sourceBlock: '.modal-content',
        url: this.$el.find('.mi-popover').data('url'),
        alternateCloseMarkup: true
      });
      detailsPopup.register();
  },

  /**
    * This function is used to open find and reserve overlay panel.
    */
  openOverlay: function(event) {
    $('#header-find-panel-trigger').trigger('click');
  },
  /**
  * This function is used to stop the event from propagating if the click is done on phone number container
  * @param {Object} event Mouse event triggered on phone number container click.
  */
  handlePhoneNumber: function(event) {
    event.stopPropagation();
  },
   /**
  * This function is used to stop the event from propagating if the click is done on email container
  * @param {Object} event Mouse event triggered on phone number container click.
  */
  handleEmail: function(event) {
    event.stopPropagation();
  },
  /**
  * This function is used to close the dropdown when clicked outside. 
  * @param {Object} event Mouse event triggered on document click. 
  */
  onDocumentClick: function (event) {
    if(this.isPhoneListOpen && this.phoneNumberContainer) {
      this.togglePhoneContainerDisplay(event);
    }
    if(this.isEmailListOpen && this.emailNumberContainer) {
      this.toggleEmailContainerDisplay(event);
    }
  },
  /**
  * This function is used to open the panel on click of Details button in small resolution. 
  */
  openDetailedView: function () {
    var detailsContainer = this.$el.find('.js-content-wrapper');
    detailsContainer.slideDown(_.bind(this.onSlideDownComplete, this));
  },
  /**
  * This function is used to hide the panel on click of Close button.  
  */
  hideDetailedView: function () {
    var detailsContainer = this.$el.find('.js-content-wrapper');
    detailsContainer.slideUp(_.bind(this.onSlideUpComplete, this));
  },
  /**
  * This callback is used to hide the Details button and show the Close button when panel is opened.
  */
  onSlideDownComplete: function () {
    this.$el.find('.details-btn-wrapper').hide();
    this.$el.find('.close-btn-wrapper').show();
  },
  /**
  * This callback is used to hide the Close button and show the Details button when panel is closed.
  */
  onSlideUpComplete: function () {
    this.$el.find('.close-btn-wrapper').hide();
    this.$el.find('.details-btn-wrapper').show();
  },
  /**
  * This function is used to toggle the contact details section
  * @returns {boolean} Execution status flag
  */
  toggleContactDetails : function _toggleContactDetails(e) {
    e.preventDefault();
    $(e.currentTarget).next('.phone-numbers, .l-phone-number-dropdown').toggleClass('is-hidden');
     if(this.isEmailListOpen && this.emailNumberContainer) {
      this.toggleEmailContainerDisplay(event);
    }
    return true;
  },
  /**
  * This function is used to toggle the email section
  * @returns {boolean} Execution status flag
  */
  toggleEmailDetails : function _toggleEmailDetails(e) {
    e.preventDefault();
    $(e.currentTarget).next('.email-list').toggleClass('is-hidden');
    if(this.isPhoneListOpen && this.phoneNumberContainer) {
      this.togglePhoneContainerDisplay(event);
    }
    return true;
  },
  /**
  * This function is used to toggle the address and contact details section
  * @returns {boolean} Execution status flag
  */
  toggleSubMenu : function _toggleSubMenu(e) {
    e.preventDefault();
    var drawerButton = $(e.currentTarget) , drawerButtonParent = drawerButton.parent();

    drawerButtonParent.next().slideToggle();
    drawerButtonParent.siblings('.m-hotel-links').slideToggle();

    if(drawerButton.hasClass('icon-drawer-open')) {
      drawerButton.addClass('icon-drawer-close').removeClass('icon-drawer-open');
    }
    else if (drawerButton.hasClass('icon-drawer-close')) {
      drawerButton.addClass('icon-drawer-open').removeClass('icon-drawer-close');
    }
    return true;
  },
  /**
  * This function registers Popup to  display photoviewer , currency calculator and rewards category
  * @param {Object} options object to set properties
  * @returns {Object} new instance of popup
  */ 
  registerPopup : function(options) {
    return new Popup(options);
  },
   /**
  * This function is used to open Hotel fact Sheet Popup
  * @param {Object} event Mouse event triggered on filter icon click.
  */
  showPopup: function () {
      var showHotelFactSheetPopup = new Popup({
        selector: '.js-hotel-detail-link',
        sourceBlock: '.modal-content',
        alternateCloseMarkup: true
      });
      showHotelFactSheetPopup.register();
  },
  /**
  * This function handles closing and opening of dropdown on pressing down arrow ,esc key and up arrow key respectively. 
  * @param {void}
  * @returns {boolean} Execution status flag
  */
  handleKeyboardControls : function _handleKeyboardControls() {
    var _self = this,
    keyValue,
    contactDetailsDropdown = this.$el.find('.js-contact-details-container a');
    contactDetailsDropdown.on('keydown',function(e) {
      keyValue = e.keyCode || e.which;
      //up arrow key is pressed
        if(keyValue === 38 && _self.isPhoneListOpen) {
           _self.togglePhoneContainerDisplay(e);
        }
      //down arrow key is pressed
        else if(keyValue === 40 &&  !_self.isPhoneListOpen) {
          _self.togglePhoneContainerDisplay(e);
        }
      //esc key is pressed 
        else if(keyValue === 27 && _self.isPhoneListOpen) {
          _self.togglePhoneContainerDisplay(e);
        }
    });  
    return true;      
  },
    /**
  * This function handles scrolling of body to map section on click of header address link
  *  @param {Object} event Mouse event triggered on header address link click.
  * @returns {void} 
  */
  scrollToMap : function _scrollToMap(event){
    var _self = this, addressId = $(event.currentTarget).attr('href'),
        indexHash = addressId.indexOf('#'), $navBar = $('#stickySummary'), navTargetTopOffset, navHeight;

    if (-1 < indexHash){
      event.preventDefault();
      navTargetTopOffset = $(addressId).offset().top;
      navHeight = $navBar.height();
      if( !$navBar.hasClass('is-sticky-top') && navTargetTopOffset > navHeight ) {
        window.scrollTo(0,navTargetTopOffset - (navHeight));
      } else {
        window.scrollTo(0,navTargetTopOffset - 150);
      }

    }

  },
  /**
   * For google map redirection,to remove white spaces in uri
   */
  removeSpace : function(){
    var element= this.$el.find('.address-wrapper a');
    var anchorValue= element && element.attr('href').trim().replaceAll(" ", "+");
    element.attr("href", anchorValue);
  }
});
